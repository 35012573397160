import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Button } from "antd";
import type { MenuProps } from "antd";

interface DropdownProps {
  buttonLabel: string;
  onSelect: (key: string) => void;
  disabledOptions: string[];
}

const SesameDropdown: React.FC<DropdownProps> = ({
  buttonLabel,
  onSelect,
  disabledOptions,
}) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Remove Exception Group",
      disabled: disabledOptions.includes("1"),
    },
    {
      key: "2",
      label: "Create new account(s)",
      children: [
        {
          key: "2-1",
          label: "Linguist/Lead",
          disabled: disabledOptions.includes("2-1"),
        },
        {
          key: "2-2",
          label: "Tester",
          disabled: disabledOptions.includes("2-2"),
        },
        {
          key: "2-3",
          label: "PM/TM/TS/Test Lead",
          disabled: disabledOptions.includes("2-3"),
        },
        {
          key: "2-4",
          label: "Life Sciences",
          disabled: disabledOptions.includes("2-4"),
        },
      ],
      disabled: disabledOptions.includes("2"),
    },
    {
      key: "3",
      label: "Reset Password",
      disabled: disabledOptions.includes("3"),
    },
    {
      key: "4",
      label: "Account Suspension",
      children: [
        {
          key: "4-1",
          label: "Suspend account(s)",
          disabled: disabledOptions.includes("4-1"),
        },
        {
          key: "4-2",
          label: "Activate account(s)",
          disabled: disabledOptions.includes("4-2"),
        },
      ],
      disabled: disabledOptions.includes("4"),
    },
  ];

  const handleClick: MenuProps["onClick"] = (e) => {
    onSelect(e.key);
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      overlayStyle={{ zIndex: 999999999 }}
    >
      <Button>
        <Space>
          {buttonLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default SesameDropdown;
