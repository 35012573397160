import { Descriptions } from "antd";
import TimezoneConverter from "../../../timezone-converter";

const PlpGeneralInformation = (props: any) => {
  const { details } = props;
  const {
    product,
    sourceLanguageCode,
    latestReviewDueTimestamp,
    latestOriginalTranslationDueTimestamp,
    componentType,
    plpStatus,
    creationTimestamp,
    lastUpdatedTimestamp,
    turnaroundTimeUrgency,
    customVendorPLPMetadata,
  } = details;

  const {
    lastImportedTimestamp,
    lastSharedDate,
    projectType,
    varStatus,
    importedTimestamp,
  } = customVendorPLPMetadata || {};

  return (
    <Descriptions bordered={true} column={2} size={"small"}>
      <Descriptions.Item label="Product">{product}</Descriptions.Item>
      <Descriptions.Item label="Source Language">
        {sourceLanguageCode}
      </Descriptions.Item>
      <Descriptions.Item label="Urgency">
        {turnaroundTimeUrgency}
      </Descriptions.Item>
      <Descriptions.Item label="Latest Translation Due Date">
        <TimezoneConverter date={latestOriginalTranslationDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Latest Review Date">
        <TimezoneConverter date={latestReviewDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Imported Timestamp">
        <TimezoneConverter date={lastImportedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Update Timestamp">
        <TimezoneConverter date={lastUpdatedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Component Type">
        {componentType}
      </Descriptions.Item>
      <Descriptions.Item label="Status">{plpStatus}</Descriptions.Item>
      <Descriptions.Item label="Received Date">
        <TimezoneConverter date={importedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Project Type">{projectType}</Descriptions.Item>
      <Descriptions.Item label="Last Shared Date">
        <TimezoneConverter date={lastSharedDate} />
      </Descriptions.Item>
      <Descriptions.Item label="Creation Timestamp">
        <TimezoneConverter date={creationTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="VAR Status">{varStatus}</Descriptions.Item>
    </Descriptions>
  );
};

export default PlpGeneralInformation;
