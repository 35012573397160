import React from "react";
import { Main } from '../components/glms-front';
import { useMercuryContext } from "../user-context";
import { glms_stage } from "../utils/variables";
import { Helmet } from "react-helmet";
import PageTitle from "../ui/PageTitle";

const LinguistManagementPage = () => {
  const { accessToken} = useMercuryContext();
  
  return (
    <div className="linguist-management">
      <Helmet>
        <title>Linguist Management - Mercury © RWS</title>
      </Helmet>
      <PageTitle title="Linguist Management" />
      <Main stage={glms_stage} tokenapp={accessToken} />
    </div>
  );
};

export default LinguistManagementPage;
