import {useMercuryContext, UserContext} from "../../user-context";
import React, {useState} from "react";
import {failedNotification, successfulNotification} from "../../utils/notifications";
import {Alert, Button, Modal, Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";
import PolyglotLogo from "../../stories/assets/polyglot-logo.svg";
import LoadingStar from "../../ui/LoadingStar";
import ReactJson from "react-json-view";

interface props {
    projects: any[]|any;
}
export default function GGLResyncButton( props:props ) {
    const
        { gpClient, gpSocket } = useMercuryContext(),
        { project:socket } = gpSocket,
        { projects } = props,
        [ output, setOutput] = useState( undefined ),
        [ loading, setLoading ] = useState(false),
        [ showModal, setShowModal ] = useState( false ),
        [ success, setSuccess ] = useState( false ),
        [ failed, setFailed ] = useState( false );

    function sendSync() {
        setLoading( true );
        setFailed(false)
        setSuccess(false)
        setOutput(undefined);

        if ( Array.isArray( projects ) ) {

            const projectIds = Array.from( new Set( props.projects.map( ( project:any ) => project.shortId ).filter( ( project:any ) => project !== null ) ) );

            // Multimode
            gpClient.post( "google-gp-v1/project/download", projectIds )
                .then( () => {
                    successfulNotification(
                        "Resync queued successfully",
                        `${projectIds.length} projects queued for download. Projects will update in realtime.`
                    )
                })
                .catch( ( e:any ) => {
                    failedNotification(`Unable to post resync request ${e.toString()}`);
                } )
                .finally( () => {
                    setLoading( false )
                })

            return;

        }

        try {

            setShowModal( true );
            setOutput( undefined )
            socket.emit( "download", [projects.shortId], ( data:any ) => {
                console.log( "data", data );
                if ( data.error ) {
                    setFailed( data.error );
                }
                else {
                    setSuccess( data )
                }
                setLoading( false )
                setOutput( data )
            })
        }
        catch ( e ) {
            console.error( e )
            setLoading(false )
            setFailed( true );
        }
    }

    const enabled = !projects || ( Array.isArray( projects ) && !projects.length );

    return <>
        <Modal
            title="Downloading project..."
            width={"75vw"}
            open={showModal}
            onCancel={() => setShowModal( false )}
            onOk={() => setShowModal( false )}
            maskClosable={false}
            >
            {
                loading &&
                <div>
                  <p>N.B. Downloading large projects, such as DTP projects can take several minutes, please be
                    patient. You may close this modal and the download will continue. The project will be updated in real time.</p>
                  <LoadingStar logo={true} animate={true} title={""}/>
                </div>
            }
            {
                success &&
                <Alert message="The Project has successfully downloaded"
                       type="success"/>
            }
            {
                failed &&
                <Alert message="The project could not be downloaded. Please check the project id." type="error"/>
            }
            {
                output && <div className={"download-output-json"} style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    margin: "10px 0 0 0",
                    borderRadius: "10px"
                }}>
                  <ReactJson src={output}/>
                </div>
            }

        </Modal>
        <Tooltip
            title={enabled ? "Select at least 1 item" : ""}
            placement="bottom"
            color={"var(--tertiary-color)"}
        >
            <Button
                onClick={() => sendSync()}
                className="action-btn edit-btn"
                // icon={<img src={PolyglotLogo} alt="polyglot"
                //            style={{marginLeft: '3px', width: 'auto', height: '20px'}}/>}
                type="primary"
                loading={loading}
                disabled={enabled}
            >
                ReSync
            </Button>
        </Tooltip>
    </>

}
