import React from "react";
import {
    TableAbstract,
    DateFilter,
    ListFilter, CustomTooltip
} from "../../Components";

import { 
    projectIdFilter, 
    productFilter,
    sourceLanguageCodeFilter,
    targetLanguageCodeFilter,
    sharingStatusFilter,
    lastImportedTimestampFilter,
    lastUpdatedTimestampFilter,
    creationTimestampFilter
} from '.././filtersConfig';

import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Tooltip} from "antd"
import {Link} from "react-router-dom";
import {BlockOutlined} from "@ant-design/icons";
import SharingPercentage from "../../ui/SharingPercentage";
import TimezoneConverter from "../../timezone-converter";
import StatusFilter from "../Status.filter";
import DueDateTierFilter from "../DueDateTier.filter";
import NumberFilter from "../MongoNumber.filter";
import {DateTime} from "luxon";
import { creationTimestampColumn, lastImportedTimestampColumn, lastUpdatedTimestampColumn } from "../columnsConfig";
class DocumentSearch extends TableAbstract<any> {
    defaultSortField = "importedDate";
    FilterComponents = [
        projectIdFilter,
        productFilter,
        sourceLanguageCodeFilter,
        targetLanguageCodeFilter,
        {
            id: "documentId",
            name: "documentId",
            title: "Document ID",
            component: ListFilter,
            active: true
        },
        {
            id: "weightedWordCount",
            title: "WWC",
            name: "weightedWordCount",
            component: NumberFilter,
            active: false
        },
        {
            id: "wordCount",
            title: "Word Count",
            name: "wordCount",
            component: NumberFilter,
            active: false
        },
        {
            id: "importedDate",
            title: "Received Date",
            name: "importedDate",
            component: DateFilter
        },
        {
            id: "dueDateTier",
            title: "Due Date Tier",
            name: "dueDateTier",
            component: DueDateTierFilter
        },
        {
            id: "translationDueTimestamp",
            title: "Due Date",
            name: "translationDueTimestamp",
            component: DateFilter
        },
        {
            id: "status",
            name: "catToolStatus",
            title: "Status",
            component: StatusFilter,
            active: true
        },
        sharingStatusFilter,
        creationTimestampFilter,
        lastImportedTimestampFilter,
        lastUpdatedTimestampFilter
    ];
    columns = [
        {
            id: "projectId",
            title: "Project ID",
            dataIndex: "catToolProjectId",
            sorter: true,
            width: 130,
            fixed: undefined,
            render: (id: string, record: any) =>
                record.catToolProjectId !== null && (
                    <>
                        <a
                            href={`/project/${id}/?type=projectId`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BlockOutlined style={{marginRight:'3px'}}/>
                        </a>
                        <Link
                            to={`/project/${id}?type=projectId`}
                            id={id}
                        >
                            {id}
                        </Link>
                    </>
                ),
        },
        {
            id: "title",
            title: "Document name",
            dataIndex: "title",
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={57}/>,
        },
        {
            id: "product",
            title: "Product",
            dataIndex: "product",
            sorter: true,
            fixed: undefined,
            width: 200,
            render: (text: string) => <CustomTooltip text={text} length={25}/>,
        },
        {
            id: "status",
            title: "Status",
            dataIndex: "catToolStatus",
            sorter: true,
            fixed: undefined,
            width: 150,
            render: (text: string) => <CustomTooltip text={text} length={17}/>
        },
        {
            id: "sourceLanguageCode",
            title: <Tooltip title="Source Language" placement="left" color={"var(--tertiary-color)"}>Source La...</Tooltip> as any,
            dataIndex: "sourceLanguageCode",
            sorter: true,
            fixed: undefined,
            width: 105,
        },
        {
            id: "targetLanguageCode",
            title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target La...</Tooltip> as any,
            dataIndex: "targetLanguageCode",
            sorter: true,
            fixed: undefined,
            width: 105,
        },
        {
            id: "documentId",
            title: "Document ID",
            dataIndex: "documentId",
            sorter: true,
            fixed: undefined,
            width: 140,
            render: (text: string) => (text.includes('tasks') ?
                <a href={`https://localization.google.com/polyglot/${text}`} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center'}}>
                    <BlockOutlined style={{marginRight: '5px' }}/><CustomTooltip text={text} length={15}/>
                </a>
                :
                <a href={`https://localization.google.com/polyglot/tasks/${text}`} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center'}}>
                    <BlockOutlined /><CustomTooltip text={text} length={15}/>
                </a>
                ),
        },
        {
            id: "weightedWordCount",
            title: <Tooltip title="Weighted Word Count" placement="left" color={"var(--tertiary-color)"}>Weighted Word Count</Tooltip> as any,
            dataIndex: "weightedWordCount",
            sorter: true,
            fixed: undefined,
            width: 110
        },
        {
            id: "wordCount",
            title: "Word Count",
            dataIndex: "wordCount",
            sorter: true,
            fixed: undefined,
            width: 110
        },
        // {
        //     id: "component",
        //     title: "Component Type",
        //     dataIndex: "componentType",
        //     sorter: true,
        //     fixed: undefined,
        //     width: 150,
        // },
        {
            id: "receivedDate",
            title: "Received Date",
            dataIndex: "importedDate",
            sorter: true,
            width: 150,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text}/>
        },
        {
            id: "dueDateTier",
            title: "Due Date Tier",
            dataIndex: "dueDateTier",
            sorter: true,
            width: 130,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={15}/>
        },
        {
            id: "translationDueTimestamp",
            title: "Due Date",
            dataIndex: "translationDueTimestamp",
            sorter: true,
            width: 150,
            fixed: undefined,
            render: (text: string) => <TimezoneConverter date={text}/>
        },
        {
            id: "sharing",
            title: "Sharing",
            dataIndex: "sharingStatus",
            sorter: true,
            width: 80,
            fixed: undefined,
            align: "center" as any,
            render: (text: string) => <SharingPercentage percentage={text} date={undefined}/>,
        },
        creationTimestampColumn,
        lastImportedTimestampColumn,
        lastUpdatedTimestampColumn
        // {
        //     id: "owner", // TODO: will be added at a later date
        //     title: "Owner",
        //     dataIndex: "ownerName",
        //     width: 260,
        //     align: "left" as AlignType,
        //     sorter: true,
        //     fixed: undefined,
        //     render: (text: any, record: any) => <UserBadge name={record.ownerName} email={record.ownerEmail} avatarSize={22} hideEmail={true} />
        // }
    ];

    renderActions() {
        return null
    }

    rowClassName(record: any, index: number): string {

        const validStatuses = [
            "NEW",
            "IN_PREPROCESSING",
            "PREPROCESSING_COMPLETE",
            "PENDING",
            "IN_TRANSLATION",
            "IN_PROOFREADING",
            "SIGN_OFF_WAITING",
            "IN_COPY_EDIT"
        ];

        if ( validStatuses.indexOf( record.catToolStatus ) !== -1 ) {

            const
                now = DateTime.now(),
                SoD = DateTime.fromISO( record.translationDueTimestamp).startOf('day'),
                EoD = DateTime.fromISO( record.translationDueTimestamp ).endOf('day');

            if ( now >= SoD && now <= EoD ) {
                return "metaRow warning"
            }

            if ( now >= DateTime.fromISO( record.translationDueTimestamp) ) {
                return "metaRow critical"
            }
        }

        return super.rowClassName(record, index);
    }

    async fetch() {

        const { sortOrder, sortField } = this.state;

        const res = await this.userContext.gpClient.post(`/google-gp-v1/document/search`, {
            filter: this.filtersToQuery(),
            // filter: this.filters,
            sort: {
                [sortField||"lastUpdatedTimestamp"]: sortOrder === "ascend" ? 1 : -1
            },
            pagination: {
                pageNumber: this.currentPage || 1,
                resultsPerPage: this.itemsPerPage
            }
        });

        return {data:res.data} as any;


    }

    pageTitle = "Document search - Mercury";
    title = "Documents";
    pageClass = "projectTable"

}

export default () => {
    const
        context = useMercuryContext(),
        breaks = useBreakpoint();

    return (<DocumentSearch id={"Documents"} noSelect={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any}/>);
}
