import {
    BooleanFilter,
    DateFilter,
    ListFilter,
} from "../Components";

import ProductFilter from "./Product.filter";
import LanguageCodeFilter from "./LanguageCode.filter";
import NumberFilter from "./MongoNumber.filter";
import SharingStatusFilter from "./SharingStatus.filter";
import PoTypeFilter from "./PoType.filter";

//*** Define abstract table filters ***//
export const projectIdFilter = {
    id: "projectId",
    name: "projectIds",
    title: "Project ID",
    component: ListFilter,
    active: true
};

export const productFilter = {
    id: "product",
    name: "product",
    title: "Product",
    component: ProductFilter,
    active: true
};

export const sourceLanguageCodeFilter = {
    id: "sourceLangCode",
    name: "sourceLanguageCode",
    title: "Source Language",
    component: LanguageCodeFilter,
    active: true
};

export const targetLanguageCodeFilter = {
    id: "targetLanguageCode",
    name: "targetLanguageCode",
    title: "Target Language",
    component: LanguageCodeFilter,
    active: true
};

export const wordRateFilter = {
    id: "wordRate",
    name: "wordRate",
    title: "Word Rate",
    component: NumberFilter,
};

export const wordChargeFilter = {
    id: "wordCharge",
    name: "wordCharge",
    title: "Word Charge",
    component: NumberFilter,
};

export const serviceRateFilter = {
    id: "serviceRate",
    name: "serviceRate",
    title: "Service Rate",
    component: NumberFilter,
};

export const serviceHoursFilter = {
    id: "serviceHours",
    name: "serviceHours",
    title: "Service Hours",
    component: NumberFilter,
};

export const serviceChargeFilter = {
    id: "serviceCharge",
    name: "serviceCharge",
    title: "Service Charge",
    component: NumberFilter,
};

export const lastImportedTimestampFilter = {
    id: "lastImportedTimestamp",
    name: "lastImportedTimestamp",
    title: "Last Imported Timestamp",
    component: DateFilter,
    active: true
};

export const lastUpdatedTimestampFilter = {
    id: "lastUpdatedTimestamp",
    name: "lastUpdatedTimestamp",
    title: "Last Updated Timestamp",
    component: DateFilter,
    active: true
};

export const creationTimestampFilter = {
    id: "creationTimestamp",
    name: "creationTimestamp",
    title: "Creation Timestamp",
    component: DateFilter,
    active: true
};

export const createdAtFilter = {
    id: "createdAt",
    title: "Creation Timestamp",
    name: "createdAt",
    component: DateFilter
};

export const dtpRateFilter = {
    id: "dtpRate",
    name: "dtpRate",
    title: "DTP Rate",
    component: NumberFilter,
};

export const dtpHoursFilter = {
    id: "dtpHours",
    name: "dtpHours",
    title: "DTP Hours",
    component: NumberFilter,
};

export const dtpChargeFilter = {
    id: "dtpCharge",
    name: "dtpCharge",
    title: "DTP Charge",
    component: NumberFilter,
};

export const engineeringRateFilter = {
    id: "engineeringRate",
    name: "engineeringRate",
    title: "Engineering Rate",
    component: NumberFilter,
};

export const engineeringHoursFilter = {
    id: "engineeringHours",
    name: "engineeringHours",
    title: "Engineering Hours",
    component: NumberFilter,
};

export const engineeringChargeFilter = {
    id: "engineeringCharge",
    name: "engineeringCharge",
    title: "Engineering Charge",
    component: NumberFilter,
};

export const pmFeeRateFilter = {
    id: "pmFeeRate",
    name: "pmFeeRate",
    title: "PM Fee rate",
    component: NumberFilter,
    active: false
};

export const pmFeeChargeFilter = {
    id: "pmFeeCharge",
    name: "pmFeeCharge",
    title: "PM Fee charge",
    component: NumberFilter,
    active: false
};

export const sharingStatusFilter = {
    id: "sharingStatus",
    name: "sharingStatus",
    title: "Sharing Status",
    component: SharingStatusFilter,
    active: false
};

export const latestTranslationDueTimestampFilter = {
    id: "latestTranslationDueTimestamp",
    name: "latestTranslationDueTimestamp",
    title: "Due Date",
    component: DateFilter,
    active: true
};

export const receivedDateFilter = {
    id: "receivedDate",
    name: "importedDate",
    title: "Received Date",
    component: DateFilter,
    active: true
};

export const poTypeFilter = {
    id: "poType",
    name: "poType",
    title: "PO Type",
    component: PoTypeFilter
};

export const activeFilter = {
    id: "isActive",
    name: "isActive",
    title: "Active",
    component: BooleanFilter
};

export const invoiceMonthFilter = {
    id: "invoiceMonth",
    title: "Invoice Month",
    name: "invoiceMonth",
    component: DateFilter,
    active: false
};