import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Space, Typography, Spin, Modal, Row, Col } from "antd";
import {
  PlusCircleOutlined,
  LoadingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import createPayload from "../helpers/createPayload";
import { saveAs } from "file-saver";
import EditLinguistic from "./EditLinguistic";
import Filters from "./Filters";
import DataTable from "./DataTable";
import Config from "../helpers/config";
import token from "../helpers/token";
import styles from "../styles.module.css";
import BulkEdit from "./BulkEdit";
import EnumApi from "../helpers/api";

const Main = ({ tokenapp, stage }) => {
  const config = new Config(stage);
  const [accessToken, setAccessToken] = useState();
  const [programRoles, setProgramRoles] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);
  const [componentTypes, setComponentTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [products, setProducts] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [showBulkEdit, setShowBulkEdit] = useState(false);
  const [valueFilters, setValueFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const [spinning, setSpinning] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const [filterQuery, setFilterQuery] = useState({});
  const [refreshLinguists, setRefreshLinguists] = useState(false);

  const [reg, setReg] = useState({});
  const [viewEditLinguistic, setViewEditLinguistic] = useState(false);

  const [loading, setLoading] = useState(true);

  const getProgramRoles = async () => {
    const response = await fetch(`${config.URL}/glms/program/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setProgramRoles(await response.json());
  };

  const getProjectRoles = async () => {
    const response = await fetch(`${config.URL}/glms/project/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setProjectRoles(await response.json());
  };

  const getComponentTypes = async () => {
    const response = await fetch(
      `${config.API_OLD}/projects/componentType/list`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    setComponentTypes(await response.json());
  };

  const getLanguages = async () => {
    const response = await fetch(`${config.API_OLD}/languageCode`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    setLanguages(await response.json());
  };

  const getProductAreas = async () => {
    const response = await fetch(
      `${config.API}/${EnumApi.CoreGpms}/productArea`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data = await response.json();
    return data;
  };

  const getProducts = async () => {
    const productAreas = await getProductAreas();
    const response = await fetch(`${config.API}/${EnumApi.CoreGpms}/product`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    for (const product of data) {
      if (product.productArea) {
        const productArea = productAreas.find(
          (x) => x._id === product.productArea
        );
        if (productArea && productArea.title) {
          product.productArea = {
            _id: productArea._id,
            title: productArea.title,
          };
        } else {
          product.productArea = { _id: "emptyArea", title: "Others" };
        }
      } else {
        product.productArea = { _id: "emptyArea", title: "Others" };
      }
    }
    setProducts(data);
  };

  const newElement = () => {
    setReg(null);
    setViewEditLinguistic(true);
  };

  const showModal = (info) => {
    Modal.info({
      title: "Linguistic Export Link",
      content: info.content,
    });
  };

  // Rules for allowing bulk edit
  const allowBulkEdit = () => {
    // Always allow bulk edit
    return true;
  };

  const handleBulkEdit = () => {
    setShowBulkEdit(true);
  };

  const exportToExcel = () => {
    let payload = createPayload(valueFilters);

    exportLinguistics(payload);
  };

  const exportLinguistics = async (filterQuery) => {
    setSpinning(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    const raw = JSON.stringify(filterQuery.query);

    fetch(`${config.URL}/glms/linguistic/export`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      cache: "no-cache",
    }).then(async (response) => {
      const r = await response.json();
      setSpinning(false);

      if (stage === undefined || stage === "qa")
        setModalInfo({ content: <a href={r}>{r}</a> });
      else saveAs(r);
    });
  };

  useEffect(() => {
    if (modalInfo != null) showModal(modalInfo);
  }, [modalInfo]);

  useEffect(() => {
    // IMPORTANT: tokenapp is being passed as a prop to this component, no need to be fetched and stored in state
    // TODO: remove accessToken and logic to retrieve tokenApp in a future refactor of glms
    async function fetchData() {
      if (!tokenapp || tokenapp.includes("invalid_token")) {
        setAccessToken(await token.getTokenApp(config));
      } else {
        setAccessToken(tokenapp);
      }
    }
    fetchData();
  }, [tokenapp, config]);

  useEffect(() => {
    async function fetchData() {
      if (accessToken) {
        await Promise.all([
          getProgramRoles(),
          getProjectRoles(),
          getComponentTypes(),
          getLanguages(),
          getProducts(),
        ]);
        setLoading(false);
      }
    }
    fetchData();
  }, [accessToken]);

  if (loading) {
    return (
      <center>
        <LoadingOutlined
          style={{
            fontSize: "40px",
            color: "var(--primary-color)",
            marginTop: "40px",
          }}
        />
      </center>
    );
  }

  return (
    <div>
      <Spin spinning={spinning} tip="Exporting...">
        <Space direction="vertical">
          <Filters
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            programRoles={programRoles}
            projectRoles={projectRoles}
            componentTypes={componentTypes}
            languages={languages}
            products={products}
            setFilterQuery={setFilterQuery}
            setRefreshLinguists={setRefreshLinguists}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
            setValueFilters={setValueFilters}
          />
          <Typography.Text>Actions:</Typography.Text>
          <Row gutter={24}>
            <Col>
              <Button
                style={{ borderRadius: "4px" }}
                type="primary"
                onClick={newElement}
              >
                <PlusCircleOutlined /> Add Vendor
              </Button>
            </Col>
            <Col>
              <Button
                style={{ borderRadius: "4px" }}
                type="primary"
                onClick={() => exportToExcel()}
              >
                {showFilters && isDirty
                  ? "Export Current Filter to Excel"
                  : "Export All Linguists to Excel"}
              </Button>
            </Col>
            <Col>
              {allowBulkEdit() && (
                <Button
                  style={{ borderRadius: "4px" }}
                  type="secondary"
                  icon={<EditOutlined />}
                  onClick={handleBulkEdit}
                >
                  {showFilters && isDirty
                    ? "Bulk Edit on Current Filter"
                    : "Bulk Edit on All Linguists"}
                </Button>
              )}
            </Col>
          </Row>
          <hr />
          <DataTable
            accessToken={accessToken}
            config={config}
            setViewEditLinguistic={setViewEditLinguistic}
            setReg={setReg}
            products={products}
            filterQuery={filterQuery}
            refreshLinguists={refreshLinguists}
            setRefreshLinguists={setRefreshLinguists}
          />
        </Space>
      </Spin>
      {viewEditLinguistic && (
        <EditLinguistic
          accessToken={accessToken}
          config={config}
          styles={styles}
          reg={reg}
          setViewEditLinguistic={setViewEditLinguistic}
          allProgramRoles={programRoles}
          allProjectRoles={projectRoles}
          allLanguages={languages}
          allComponentTypes={componentTypes}
          allProducts={products}
          setRefreshLinguists={setRefreshLinguists}
        />
      )}
      {showBulkEdit && (
        <BulkEdit
          accessToken={accessToken}
          config={config}
          styles={styles}
          setShowBulkEdit={setShowBulkEdit}
          allComponentTypes={componentTypes}
          allProducts={products}
          allProjectRoles={projectRoles}
          allLanguages={languages}
          query={createPayload(valueFilters).query}
          setRefreshLinguists={setRefreshLinguists}
        />
      )}
    </div>
  );
};

Main.propTypes = {
  tokenapp: PropTypes.string,
  stage: PropTypes.string,
};

export default Main;